import { StaticImage } from "gatsby-plugin-image"
import React, { ReactElement } from "react"
import styled from "styled-components"
import { Flex } from "../../atoms/Flex/Flex"

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Avatar = styled(Flex)`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 24px;
`

const Mention = styled(Flex)`
  flex-direction: row;
  margin-bottom: 24px;
`

const Quote = styled(Flex)`
  font-family: monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 34px;

  display: flex;
  align-items: flex-start;
  text-align: center;

  color: #78bfd6;

  margin: 16px;
`

const MentionText = styled(Flex)`
  font-style: italic;
  font-weight: 300;
  font-size: 22px;
  line-height: 34px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`

const MentionSubtitle = styled(Flex)`
  font-size: 16px;
  line-height: 34px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Name = styled(Flex)`
  font-style: normal;
  font-weight: bold;

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`
const NameDivider = styled(Flex)`
  font-style: normal;
  font-weight: normal;

  display: flex;
  align-items: center;
  text-align: center;

  color: #727272;
  margin: 0 8px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const JobTitle = styled(Flex)`
  font-style: normal;
  font-weight: normal;

  display: flex;
  align-items: center;
  text-align: center;

  color: #727272;
`

export interface SocialMentionProps {
  img: ReactElement
  mention: string | ReactElement
  authorName: string
  jotTitle: string
  className?: string
}

export const SocialMention: React.FC<SocialMentionProps> = ({
  img,
  mention,
  authorName,
  jotTitle,
  className,
}) => (
  <Wrapper className={className}>
    <Avatar>{img}</Avatar>
    <Mention>
      <Quote>&#34;</Quote>
      <MentionText>{mention}</MentionText>
      <Quote>&#34;</Quote>
    </Mention>
    <MentionSubtitle>
      <Name>{authorName}</Name>
      <NameDivider>|</NameDivider>
      <JobTitle>{jotTitle}</JobTitle>
    </MentionSubtitle>
  </Wrapper>
)
