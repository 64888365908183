import * as React from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Flex } from "../components/atoms/Flex/Flex"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Tweets } from "../components/molecules/Tweets/Tweets"
import styled from "styled-components"
import { Footer } from "../components/molecules/Footer/Footer"
import { SocialMentionCarousel } from "../components/organisms/SocialMentionCarousel/SocialMentionCarousel"
import { slideUpInWithDelay } from "../components/atoms/Animations/Animations"
import { Button } from "../components/molecules/Button/Button"
import { Gap } from "../components/atoms/Gap/Gap"

const initTypeForm = () => {
  ;(function () {
    var qs,
      js,
      q,
      s,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = "typef_orm_share",
      b = "https://embed.typeform.com/"
    if (!gi.call(d, id)) {
      js = ce.call(d, "script")
      js.id = id
      js.src = b + "embed.js"
      q = gt.call(d, "script")[0]
      q.parentNode.insertBefore(js, q)
    }
  })()
}

const TwitterStats = {
  tweetsNumber: "257",
  tweetImpressions: "105K",
  profileVisits: "6,752",
  mentions: "141",
  followers: "1,049",
}

const Anchor = styled.a`
  color: #2799bd;

  :active {
    color: #2799bd;
  }

  :visited {
    color: #2799bd;
  }

  :hover {
    color: #1c7692;
  }
`

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 998px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const LinearGradientBox = styled.div`
  width: 100vw;
  background: linear-gradient(
    124.24deg,
    #30d8fb 0%,
    #4870c4 49.48%,
    #f33191 100%
  );
`

const Header = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 32px;
  box-sizing: border-box;
`

const Section = styled.div`
  display: flex;
  padding: 10vh;

  @media only screen and (max-width: 768px) {
    padding: 2vh;
  }
`

const CenteredSection = styled(Section)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 78px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;

  margin: 32px 0 64px;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    margin: 32px 0 32px;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 30px;
    line-height: 42px;
  }
`

const AnimatedTitle = styled(Title)`
  margin: 0 0 16px 0;
  text-shadow: 0 2px 0 #6f61b8;

  animation: ${slideUpInWithDelay} 1.6s ease-in-out;
`
const SecondAnimatedTitle = styled(Title)`
  margin: 0;
  text-shadow: 0 2px 0 #6f61b8;

  animation: ${slideUpInWithDelay} 3.2s ease-in-out;
`

const Subtitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0;
`

const AvatarWrapper = styled(Flex)`
  width: 175px;
  height: 175px;
  margin-right: 32px;

  @media only screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`

const MainButton = styled(Button)`
  font-weight: 700;
  padding: 16px 64px;
`

const MainButtonAnchor = styled.a`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.48);
  border-radius: 53px;
  border: none;
  outline: none;
  color: white !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  height: auto;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
  text-decoration: none;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, #000000 100%);

  font-weight: 700;
  padding: 16px 64px;

  :hover {
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.34);
    transform: translateY(-2px);
  }

  :active {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, #000000 100%);
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`

const BlackText = styled.span`
  color: black;
  font-weight: 700;
`

const Hr = styled.hr`
  height: 1px;
  width: 900px;
  background-color: #e5e5e5;
  border: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const CompanyBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const CompanyLogo = styled.div`
  display: flex;
  width: 112px;
  min-width: 112px;
  flex: 1 1 112px;
  flex-grow: 1;
  margin-right: 77px;
  align-self: flex-start;

  @media only screen and (max-width: 768px) {
    align-self: center;
    margin-right: 0;
    margin-bottom: 16px;
    width: auto;
    min-width: auto;
    flex: 1 1 auto;
  }
`
const CompanyBody = styled.div`
  display: flex;
  flex-direction: column;
`
const CompanyTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  color: #1a202c;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`
const CompanyJobDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 32px;
  color: #797979;
  font-weight: 300;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
  }
`

const SideProjectHead = styled(Flex)`
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
`

const SideProjectLogo = styled(Flex)`
  margin-bottom: 16px;
`

const SideProjectImage = styled(Flex)`
  margin-top: 24px;
  flex-direction: column;
  padding: 64px 0 0 0;
  background-color: #ffbd3f;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  height: auto;

  img {
    width: 656px;
    height: auto;
    border: 1px solid #eb9b00;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 7px 13px -4px #cf8a03;
    margin-bottom: -1px;
  }

  img:nth-child(1) {
    border-bottom: 0;
    height: 40px;
  }

  img:nth-child(2) {
    border-radius: 0;
    border-top: 0;
    height: 350px;
  }

  @media only screen and (max-width: 656px) {
    padding: 0;
    background-color: transparent;
    border-radius: 0;

    img {
      width: 100%;
      border: 0;
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 450px) {
    img:nth-child(1) {
      height: 20px;
    }

    img:nth-child(2) {
      height: 175px;
    }
  }
`

const Purple = styled.span`
  color: #6610f2;
  background: #ece5ff;
`

const TagContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;

  > div:nth-child(odd) {
    background: #aaf3e7;
  }

  > div:nth-child(even) {
    background: #b6dff5;
  }
`

const Tag = styled.div`
  display: inline-flex;
  padding: 4px 24px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding: 4px 12px;
  }
`

const TwitterTitle = styled(Flex)`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 78px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  color: #349fc1;
  word-spacing: 2px;
`

const TwitterStatContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const TwitterStat = styled(Flex)`
  flex-direction: column;
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
    align-items: flex-start;
  }
`

const TwitterStatTitle = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #898989;
`

const TwitterStatData = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  font-weight: 300;
  display: flex;
  align-items: center;
  color: #636262;
`

const TwitterDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 32px;
  color: #797979;
  font-weight: 300;
  width: 640px;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
  }
`

const StyledSocialMentionCarousel = styled(SocialMentionCarousel)`
  @media only screen and (max-width: 768px) {
    margin-top: 64px;
  }
`

const HeaderAnchor = styled(Anchor)`
  color: white !important;
  text-decoration: none;
  margin-right: 32px;

  :hover {
    color: white;
    text-decoration: underline;
  }
`

const IndexPage = () => {
  useEffect(() => {
    initTypeForm()
  }, [])

  const avatarData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          fluid(maxWidth: 175) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Luca Restagno</title>
        <link rel="canonical" href="https://www.lucarestagno.com/" />
      </Helmet>
      <Container>
        <LinearGradientBox>
          <Content>
            <Header>
              <HeaderAnchor href="/blog">Blog</HeaderAnchor>
              <a href="https://www.twitter.com/ikoichi" target="_blank">
                <StaticImage
                  src="../images/twitter.svg"
                  alt="twitter"
                  width={32}
                  quality={100}
                />
              </a>
            </Header>
            <CenteredSection>
              <Flex flexDirection="row">
                <AvatarWrapper>
                  <Img
                    fixed={avatarData.file.childImageSharp.fluid}
                    alt="Luca Restagno Picture"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      display: "flex",
                    }}
                  />
                </AvatarWrapper>
                <Flex flexDirection="column" alignItems="flex-start">
                  <Title style={{ marginBottom: "8px" }}>👋</Title>
                  <Title
                    style={{
                      marginTop: "0",
                      marginBottom: "24px",
                      alignItems: "baseline",
                    }}
                  >
                    <small>My name is &nbsp;</small>
                    <BlackText>Luca Restagno</BlackText>
                  </Title>
                </Flex>
              </Flex>
              <Gap height={66} />
              <AnimatedTitle>I build web products</AnimatedTitle>
              <SecondAnimatedTitle>I mentor professionals</SecondAnimatedTitle>

              <Gap height={90} />
              <div style={{ textAlign: "center" }}>
                <MainButtonAnchor
                  className="typeform-share button"
                  href="https://form.typeform.com/to/xElR5oaT?typeform-medium=embed-snippet"
                  data-mode="popup"
                  data-size="100"
                  target="_blank"
                >
                  Schedule a 1 hour session
                </MainButtonAnchor>
              </div>
              <Gap height={40} />
            </CenteredSection>
          </Content>
        </LinearGradientBox>

        <Content>
          <CenteredSection>
            <StyledSocialMentionCarousel />
          </CenteredSection>
          <Hr />
        </Content>

        <Content>
          <CenteredSection>
            <SectionTitle>🏢 Companies</SectionTitle>

            <CompanyBox>
              <CompanyLogo>
                <StaticImage
                  src="../images/lmn.svg"
                  alt="lastminute.com"
                  width={112}
                  height={28}
                  quality={100}
                  objectFit="contain"
                />
              </CompanyLogo>
              <CompanyBody>
                <CompanyTitle>
                  Senior Software Engineer and Team Lead
                </CompanyTitle>
                <CompanyJobDescription>
                  I am currently working as a Team Lead and Software Engineer in
                  a Scrum Team to boost the ancillaries products of the LMN
                  Group OTAs (lastminute.com, bravofly.com, volagratis.it,
                  rumbo.es).
                  <br />
                  <br />I work with Scrum Agile methodologies and I apply XP
                  programming principles.
                </CompanyJobDescription>
              </CompanyBody>
            </CompanyBox>

            <CompanyBox>
              <CompanyLogo>
                <StaticImage
                  src="../images/pioneer.svg"
                  alt="pioneer"
                  width={58}
                  height={48}
                  quality={100}
                  objectFit="contain"
                />
              </CompanyLogo>
              <CompanyBody>
                <CompanyTitle>
                  Senior Web Software Engineer and Team Lead
                </CompanyTitle>
                <CompanyJobDescription>
                  At Pioneer I lead a team of software developers to deliver a
                  internally used business-critical software.
                  <br />
                  <br />
                  Moreover, I took responsibility for dealing with the business,
                  collect the requirements, organize the team, and manage
                  development projects.
                  <br />
                  <br />
                  The team was globally distributed (USA and India).
                  <br />
                  <br />I work with Scrum Agile methodologies and I apply XP
                  programming principles.
                </CompanyJobDescription>
              </CompanyBody>
            </CompanyBox>

            <CompanyBox>
              <CompanyLogo>
                <StaticImage
                  src="../images/eidoo.svg"
                  alt="eidoo"
                  width={112}
                  height={32}
                  quality={100}
                  objectFit="contain"
                />
              </CompanyLogo>
              <CompanyBody>
                <CompanyTitle>
                  Mobile Front-End Engineer and Tech Lead
                </CompanyTitle>
                <CompanyJobDescription>
                  In Eidoo I worked in a lean and strongly motived team to bring
                  to the public a crypto currency mobile wallet app.
                  <br />
                  <br />
                  In this environment I learnt the blockchain technology and I
                  started applying to that.
                  <br />
                  <br />
                  I worked on hiring talents, and the team passed from 2 Senior
                  Devs, to a 12 members team in 12 months.
                  <br />
                  <br />I mentored Junior Devs and I defined their growth plan.
                </CompanyJobDescription>
              </CompanyBody>
            </CompanyBox>
          </CenteredSection>

          <Hr />
        </Content>
        <Content>
          <CenteredSection>
            <SectionTitle>🚀 Side projects</SectionTitle>

            <SideProjectHead>
              <SideProjectLogo>
                <StaticImage
                  src="../images/iterspace.svg"
                  alt="iterspace.com"
                  width={200}
                  height={32}
                  quality={100}
                  objectFit="contain"
                />
              </SideProjectLogo>
              <Anchor href="https://www.iterspace.com" target="_blank">
                https://iterspace.com
              </Anchor>
            </SideProjectHead>
            <CompanyJobDescription>
              iterspace is the web product that helps your team to share
              efficient feedbacks.
              <br />
              <br />
              Replace screenshots and hard-to-understand chat messages with{" "}
              <Purple>videos recordings</Purple>,{" "}
              <Purple>think out loud sessions</Purple> and an{" "}
              <Purple>integrated JavaScript console</Purple>.
              <br />
              It makes Businness Owners, Designers, and Developers happy to work
              together again.
            </CompanyJobDescription>
            <SideProjectImage>
              <img
                src="https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2.png"
                loading="lazy"
                sizes="(max-width: 479px) 87vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, 89vw"
                srcSet="https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2-p-500.png 500w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2-p-800.png 800w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2-p-1080.png 1080w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2-p-1600.png 1600w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2-p-2000.png 2000w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f07692459341a877a6c_navbar_2.png 2560w"
                alt=""
              />
              <img
                src="https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f122363126edb7c3f00_demo_2.png"
                loading="lazy"
                sizes="(max-width: 479px) 87vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, 89vw"
                srcSet="https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f122363126edb7c3f00_demo_2-p-500.png 500w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f122363126edb7c3f00_demo_2-p-800.png 800w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f122363126edb7c3f00_demo_2-p-1080.png 1080w, https://uploads-ssl.webflow.com/5f23d96f4606127a5a81eb12/60377f122363126edb7c3f00_demo_2.png 1500w"
                alt=""
              />
            </SideProjectImage>
            <Gap height={64} />
          </CenteredSection>

          <Hr />
        </Content>
        <Content>
          <CenteredSection>
            <SectionTitle>✍️ Competencies</SectionTitle>
            <TagContainer>
              <Tag>javascript</Tag>
              <Tag>react</Tag>
              <Tag>testing</Tag>
              <Tag>leadership</Tag>
              <Tag>build an audience</Tag>
              <Tag>amazon web services</Tag>
              <Tag>typescript</Tag>
              <Tag>scrum</Tag>
              <Tag>agile</Tag>
              <Tag>twitter</Tag>
              <Tag>node.js</Tag>
              <Tag>tech career</Tag>
            </TagContainer>
            <Gap height={80} />
          </CenteredSection>

          <Hr />
        </Content>
        <Content>
          <CenteredSection>
            <SectionTitle>
              <StaticImage
                src="../images/twitter.svg"
                alt="twitter"
                width={40}
                quality={100}
              />
              &nbsp; Audience building
            </SectionTitle>
            <TwitterTitle>MY 28 DAYS LIVE STATS ON TWITTER</TwitterTitle>
            <TwitterStatContainer>
              <TwitterStat>
                <TwitterStatTitle>Tweets</TwitterStatTitle>
                <TwitterStatData>{TwitterStats.tweetsNumber}</TwitterStatData>
              </TwitterStat>
              <TwitterStat>
                <TwitterStatTitle>Tweet impressions</TwitterStatTitle>
                <TwitterStatData>
                  {TwitterStats.tweetImpressions}
                </TwitterStatData>
              </TwitterStat>
              <TwitterStat>
                <TwitterStatTitle>Profile visits</TwitterStatTitle>
                <TwitterStatData>{TwitterStats.profileVisits}</TwitterStatData>
              </TwitterStat>
              <TwitterStat>
                <TwitterStatTitle>Mentions</TwitterStatTitle>
                <TwitterStatData>{TwitterStats.mentions}</TwitterStatData>
              </TwitterStat>
              <TwitterStat>
                <TwitterStatTitle>Followers</TwitterStatTitle>
                <TwitterStatData>{TwitterStats.followers}</TwitterStatData>
              </TwitterStat>
            </TwitterStatContainer>
            <Gap height={64} />
            <TwitterDescription>
              I regularly post stuff about Web Development on Twitter so make
              sure to{" "}
              <Anchor href="https://twitter.com/ikoichi" target="_blank">
                follow me
              </Anchor>{" "}
              if you're interested
            </TwitterDescription>
            <Gap height={16} />
            <Tweets />
          </CenteredSection>
        </Content>
        <LinearGradientBox>
          <CenteredSection>
            <Title>Level up your career</Title>
            <Subtitle>by leveraging my 10+ years experience</Subtitle>
            <Gap height={64} />
            <div style={{ textAlign: "center" }}>
              <MainButtonAnchor
                className="typeform-share button"
                href="https://form.typeform.com/to/xElR5oaT?typeform-medium=embed-snippet"
                data-mode="popup"
                data-size="100"
                target="_blank"
              >
                Schedule a 1 hour session
              </MainButtonAnchor>
            </div>
            <Gap height={40} />
          </CenteredSection>
        </LinearGradientBox>
        <Footer />
      </Container>
    </Layout>
  )
}

export default IndexPage
