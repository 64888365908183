import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface TweetsPros {
  className?: string
}

export const Tweets: React.FC<TweetsPros> = ({ className }) => (
  <Wrapper className={className}>
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        23 Terminal commands every developer should know
        <br />
        <br />A Thread 🧵{" "}
        <a href="https://t.co/UxzOGzBLPX">pic.twitter.com/UxzOGzBLPX</a>
      </p>
      &mdash; Luca Restagno ⚡ (@ikoichi){" "}
      <a href="https://twitter.com/ikoichi/status/1420046989379637253?ref_src=twsrc%5Etfw">
        July 27, 2021
      </a>
    </blockquote>{" "}
    <script
      async
      src="https://platform.twitter.com/widgets.js"
      charSet="utf-8"
    ></script>
    {/* */}
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        👩‍💻 👨‍💻 JavaScript functions explained{" "}
        <a href="https://t.co/ABaPj9S5TO">pic.twitter.com/ABaPj9S5TO</a>
      </p>
      &mdash; Luca Restagno ⚡ (@ikoichi){" "}
      <a href="https://twitter.com/ikoichi/status/1416040413258035201?ref_src=twsrc%5Etfw">
        July 16, 2021
      </a>
    </blockquote>{" "}
    {/* */}
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        📚 An introduction to versioning with Git.
        <br />
        <br />
        Let&#39;s see how Alice started contributing to an open-source project
        🙆🏻‍♀️
        <br />
        <br />A Storytelling Thread 🧵{" "}
        <a href="https://t.co/1cecx2yBtt">pic.twitter.com/1cecx2yBtt</a>
      </p>
      &mdash; Luca Restagno ⚡ (@ikoichi){" "}
      <a href="https://twitter.com/ikoichi/status/1420415844732096515?ref_src=twsrc%5Etfw">
        July 28, 2021
      </a>
    </blockquote>{" "}
    {/* */}
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        🛠 6 Web Console methods every front-end developer should know
        <br />
        <br />A visual thread 🧵{" "}
        <a href="https://t.co/XnocZzDwLs">pic.twitter.com/XnocZzDwLs</a>
      </p>
      &mdash; Luca Restagno ⚡ (@ikoichi){" "}
      <a href="https://twitter.com/ikoichi/status/1418574915805057026?ref_src=twsrc%5Etfw">
        July 23, 2021
      </a>
    </blockquote>{" "}
    {/* */}
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        🥜 TypeScript in a nutshell{" "}
        <a href="https://t.co/UCjbddCJSR">pic.twitter.com/UCjbddCJSR</a>
      </p>
      &mdash; Luca Restagno ⚡ (@ikoichi){" "}
      <a href="https://twitter.com/ikoichi/status/1417122720161189891?ref_src=twsrc%5Etfw">
        July 19, 2021
      </a>
    </blockquote>{" "}
    <script
      async
      src="https://platform.twitter.com/widgets.js"
      charSet="utf-8"
    ></script>
  </Wrapper>
)
