import React from "react"
import styled, { css, keyframes } from "styled-components"
import { Flex } from "../../atoms/Flex/Flex"

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  width: 100%;
  display: flex;
`

const ItemsContainer = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(6px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const cssFadeIn = css`
  animation: ${fadeIn} 0.3s ease-in-out;
  animation-iteration-count: 1;
`

const fadeOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-40px);
  }
`

const cssFadeOut = css`
  animation: ${fadeOut} 0.3s ease-in-out;
  animation-iteration-count: 1;
`

const Item = styled(Flex)<{
  isVisible: boolean
  fadeIn: boolean
  fadeOut: boolean
}>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  ${({ fadeIn }) => (fadeIn ? cssFadeIn : "")};
  ${({ fadeOut }) => (fadeOut ? cssFadeOut : "")};
`

const ControlsContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Control = styled(Flex)`
  margin-right: 8px;

  @media only screen and (max-width: 768px) {
    margin-right: 16px;
  }

  &&:last-child {
    margin-right: 0;
  }
`

export interface CarouselProps {
  items: Array<React.ReactElement>
  controlComponent: React.ReactElement
  activeControlComponent: React.ReactElement
  className?: string
}

export const Carousel: React.FC<CarouselProps> = ({
  items,
  controlComponent,
  activeControlComponent,
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const [nextIndex, setNextIndex] = React.useState<number | null>(null)
  const [previousIndex, setPreviousIndex] = React.useState<number | null>(null)
  const [fadeInIndex, setFadeInIndex] = React.useState<number | null>(null)
  const [fadeOutIndex, setFadeOutIndex] = React.useState<number | null>(null)

  React.useEffect(() => {
    if (nextIndex !== null) {
      setFadeOutIndex(currentIndex)

      setTimeout(() => {
        setFadeInIndex(nextIndex)
        setPreviousIndex(currentIndex)
        setCurrentIndex(nextIndex as number)
      }, 250)
    }
  }, [nextIndex])

  return (
    <Wrapper {...props}>
      <ItemsContainer>
        {items.map((item, index) => (
          <Item
            key={`item-${index}`}
            isVisible={index === currentIndex}
            fadeIn={index === fadeInIndex}
            fadeOut={index === fadeOutIndex}
          >
            {item}
          </Item>
        ))}
      </ItemsContainer>
      {controlComponent && (
        <ControlsContainer>
          {items.map((item, index) => (
            <Control key={index} onClick={() => setNextIndex(index)}>
              {currentIndex === index
                ? activeControlComponent
                : controlComponent}
            </Control>
          ))}
        </ControlsContainer>
      )}
    </Wrapper>
  )
}
