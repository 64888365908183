import React from "react"
import styled from "styled-components"
import { Flex } from "../../atoms/Flex/Flex"
import { SocialMention } from "../../molecules/SocialMention/SocialMention"
import { Carousel } from "../../molecules/Carousel/Carousel"
import { StaticImage } from "gatsby-plugin-image"
import useMobile from "../../hooks/useMobile"

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 716px;

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`

const Lenza = (
  <SocialMention
    img={
      <StaticImage
        src="../../../images/davide_lenzarini.jpeg"
        alt="avatar"
        width={90}
        height={90}
        quality={100}
        objectFit="contain"
      />
    }
    authorName="Davide Lenzarini"
    jotTitle="IoT Architect at u-blox"
    mention={
      <>
        I worked with Luca for several years, and I can say he is a professional
        and a team worker. With him on board, we built unique products.
      </>
    }
  />
)

const Marco = (
  <SocialMention
    img={
      <StaticImage
        src="../../../images/marco_zani.jpeg"
        alt="avatar"
        width={90}
        height={90}
        quality={100}
        objectFit="contain"
      />
    }
    authorName="Marco Zani"
    jotTitle="User Experience Designer at lastminute.com"
    mention={
      <>
        With an extremely rare eye for efficient ux and delightful interactions,
        Luca brings an outstanding contribution to product teams, both as a
        frontend lead and full stack developer.
      </>
    }
  />
)

const Flavio = (
  <SocialMention
    img={
      <StaticImage
        src="../../../images/flavio_cozzoli.jpeg"
        alt="avatar"
        width={90}
        height={90}
        quality={100}
        objectFit="contain"
      />
    }
    authorName="Flavio Cozzoli"
    jotTitle="Head of Digital Agronomy Europe at Corteva Agriscience"
    mention={
      <>
        Luca is a professional and a team worker.
        <br />
        With him on board we built amazing products.
      </>
    }
  />
)

const items = [Marco, Lenza /*, Flavio */]

const ControlComponent = styled(Flex)`
  width: 9px;
  height: 9px;
  background: #ffffff;
  border: 1px solid #8f8f8f;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 16px;

  :hover {
    border: 1px solid #575757;
  }

  @media only screen and (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
`

const ActiveControlComponent = styled(ControlComponent)`
  background: #3f3f3f;
  border: 1px solid #3f3f3f;
`

export interface SocialMentionCarouselProps {
  className?: string
}

export const SocialMentionCarousel: React.FC<SocialMentionCarouselProps> = ({
  className,
}) => (
  <Wrapper className={className}>
    <Carousel
      items={items}
      controlComponent={<ControlComponent />}
      activeControlComponent={<ActiveControlComponent />} />
  </Wrapper>
)
