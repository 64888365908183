import React, { ReactNode } from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.48);
  border-radius: 53px;
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  height: auto;
  padding: 4px 16px;
  cursor: pointer;

  transition: all 0.3s ease-in-out;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, #000000 100%);

  :hover {
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.34);
    transform: translateY(-2px);
  }

  :active {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, #000000 100%);
  }
`

interface ButtonProps {
  children: ReactNode
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)
