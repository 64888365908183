import { keyframes } from "styled-components";

export const slideUpIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(6px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const slideUpInWithDelay = keyframes`
  0% {
    opacity: 0;
    transform: translateY(6px);
  }
  50% {
    opacity: 0;
    transform: translateY(6px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`